<template>
  <div>
    <b-modal
      ref="my-modal"
      v-model="IsPopupActiveLocal"
      :title="$t('Details')"
      hide-footer
    >
      <b-row align-h="center">
        <b-col
          cols="12"
        >
          <h2>Profile Summary</h2>
          <p
            class="mt-2 mb-2"
          >
            {{ UserData.profile_summary }}
          </p>
        </b-col>
        <b-col
          cols="12"
          class="mt-1 mb-1"
        >
          <hr>
        </b-col>
        <b-col
          cols="12"
        >
          <b-row>

            <b-col
              cols="12"
              class="card_border mb-2"
            >
              <h2>Experience</h2>
              <b-row
                v-for="(tr, indextr) in UserData.experiences"
                :key="indextr"
                class="mt-2 mb-2 item"
              >
                <b-col
                  cols="12"
                  class="mb-1"
                  lg="6"
                >
                  <h5><b>{{ tr.title }}</b></h5>
                  <span>{{ tr.description }}</span>
                </b-col>
                <b-col
                  cols="12"
                  class="mb-1"
                  lg="6"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <vx-badge
                        v-if="tr.from && tr.to"
                        color="light-primary"
                      >
                        {{ tr.from }} - {{ tr.to }}
                      </vx-badge>
                      <vx-badge
                        v-else-if="tr.from"
                        color="light-primary"
                      >
                        {{ tr.from }}
                      </vx-badge>
                      <vx-badge
                        v-else
                        color="light-primary"
                      >
                        No Date specified
                      </vx-badge>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="mt-1 mb-1"
            >
              <hr>
            </b-col>
            <b-col
              cols="12"
              class="card_border"
            >
              <h2>Education</h2>
              <b-row
                v-for="(tr, indextr) in UserData.educations"
                :key="indextr"
                class="mt-2 mb-2 item"
              >
                <b-col
                  cols="12"
                  class="mb-1"
                  lg="6"
                >
                  <h5><b>{{ tr.title }}</b></h5>
                </b-col>
                <b-col
                  cols="12"
                  class="mb-1"
                  lg="6"
                >
                  <vx-badge
                    v-if="tr.start && tr.finish"
                    color="light-primary"
                  >
                    {{ tr.start }} - {{ tr.finish }}
                  </vx-badge>
                  <vx-badge
                    v-else-if="tr.start"
                    color="light-primary"
                  >
                    {{ tr.start }}
                  </vx-badge>
                  <vx-badge
                    v-else
                    color="light-primary"
                  >
                    No Date specified
                  </vx-badge>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="mt-1 mb-1"
            >
              <hr>
            </b-col>
            <b-col
              cols="12"
              class="card_border"
            >
              <h2>Volunteering Work</h2>
              <b-row
                v-for="(tr, indextr) in UserData.volunteering_works"
                :key="indextr"
                class="mt-2 mb-2 item"
              >
                <b-col
                  cols="12"
                  class="mb-1"
                  lg="6"
                >
                  <h5><b>{{ tr.title }}</b></h5>
                  <span>{{ tr.description }}</span>
                </b-col>
                <b-col
                  cols="12"
                  class="mb-1"
                  lg="6"
                >
                  <vx-badge
                    v-if="tr.from && tr.to"
                    color="light-primary"
                  >
                    {{ tr.from }} - {{ tr.to }}
                  </vx-badge>
                  <vx-badge
                    v-else-if="tr.from"
                    color="light-primary"
                  >
                    {{ tr.from }}
                  </vx-badge>
                  <vx-badge
                    v-else
                    color="light-primary"
                  >
                    No Date specified
                  </vx-badge>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="mt-1 mb-1"
            >
              <hr>
            </b-col>
            <b-col
              cols="12"
              class="card_border"
            >
              <h2>Additional Information</h2>
              <p class="item">
                {{ UserData.additional_information }}
              </p>
            </b-col>
            <b-col
              cols="12"
              class="mt-1 mb-1"
            >
              <hr>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <h2>Interests</h2>
              <b-row
                class="mt-2 mb-2"
              >
                <b-col
                  v-for="(tr, indextr) in UserData.interests"
                  :key="indextr"
                  cols="4"
                  class="mt-1"
                >
                  <vx-badge
                    color="light-success"
                  >
                    {{ tr.title }}
                  </vx-badge>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="mt-1 mb-1"
            >
              <hr>
            </b-col>
            <b-col
              cols="12"
            >
              <h2>Desired Job</h2>
              <b-row class="mt-2 mb-2">
                <b-col
                  v-for="(tr, indextr) in UserData.occupations"
                  :key="indextr"
                  cols="4"
                >
                  <vx-badge
                    color="light-success"
                  >
                    {{ tr.title }}
                  </vx-badge>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="mt-1 mb-1"
            >
              <hr>
            </b-col>
            <b-col
              cols="12"
            >
              <h2>Languages</h2>
              <b-row
                v-for="(tr, indextr) in UserData.languages"
                :key="indextr"
                class="mt-2 mb-2"
              >
                <b-col
                  cols="6"
                >
                  <vx-badge
                    color="light-success"
                  >
                    {{ tr.language }}
                  </vx-badge>
                </b-col>
                <b-col
                  cols="6"
                >
                  {{ tr.status }}
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="mt-1 mb-1"
            >
              <hr>
            </b-col>
            <b-col
              cols="12"
            >
              <h2>Other</h2>
              <b-row class="mt-2 mb-2">
                <b-col cols="6">
                  <vx-badge
                    color="light-success"
                  >
                    Work Premit
                  </vx-badge>
                </b-col>
                <b-col cols="6">
                  <fa-icon
                    v-if="UserData.work_permit"
                    pack="fas"
                    icon="check"
                    color="success"
                  />
                  <fa-icon
                    v-else
                    pack="fas"
                    icon="times"
                    color="danger"
                  />
                </b-col>
                <b-col
                  class="mt-1"
                  cols="6"
                >
                  <vx-badge
                    color="light-success"
                  >
                    Refugee Status
                  </vx-badge>
                </b-col>
                <b-col
                  class="mt-1"
                  cols="6"
                >
                  <fa-icon
                    v-if="UserData.refugee_status"
                    pack="fas"
                    icon="check"
                    color="success"
                  />
                  <fa-icon
                    v-else
                    pack="fas"
                    icon="times"
                    color="danger"
                  />
                </b-col>
                <b-col
                  class="mt-1"
                  cols="6"
                >
                  <vx-badge
                    color="light-success"
                  >
                    Employment Type
                  </vx-badge>
                </b-col>
                <b-col
                  class="mt-1"
                  cols="6"
                >
                  <vx-badge color="light-success">
                    {{ UserData.employment_type }}
                  </vx-badge>
                </b-col>
                <b-col
                  class="mt-1"
                  cols="6"
                >
                  <vx-badge
                    color="light-success"
                  >
                    Availability
                  </vx-badge>
                </b-col>
                <b-col
                  class="mt-1"
                  cols="6"
                >
                  <vx-badge :color="UserData.status==='available'?'light-success':'light-danger'">
                    {{ UserData.status }}
                  </vx-badge>
                </b-col>
                <b-col
                  class="mt-1"
                  cols="6"
                >
                  <vx-badge
                    color="light-success"
                  >
                    Desired Job Location
                  </vx-badge>
                </b-col>
                <b-col
                  class="mt-1"
                  cols="6"
                >
                  <vx-badge color="light-success">
                    {{ UserData.job_address }}
                  </vx-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

/* eslint-disable */
export default {
  name: "ViewCandidate",
  props: ['value','UserData'],

  data: () => ({
  }),
  computed: {
    IsPopupActiveLocal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },

    },
  },
}
</script>

<style lang="scss">
</style>
