<template>
  <div>
    <view-candidate
      v-model="viewMore"
      :user-data="candidate"
    />
    <b-row class="justify-content-center">
      <b-col
        cols="12"
        class="py-1"
      >
        <h3 class="text-center">
          Are you looking for employees? And do you want to make diversity in your company easy?<br>
          Get in touch with us to find out more highly motivated refugee jobseekers' CVs.
          <br>
          We would like to hear from you!
          <br>
          contact@employrefugees.com
        </h3>
      </b-col>
      <b-col
        class="py-5"
        cols="12"
      >
        <b-row class="justify-content-center">
          <b-col
            cols="12"
            sm="8"
            md="6"
            lg="4"
            class="py-1"
          >
            <div class="form-label-group">
              <b-form-input
                id="search-input"
                v-model="searchInput"
                placeholder="Search Candidates"
                type="search"
                :state="SearchStatus"
                @keyup.enter="search"
              />
              <b-form-valid-feedback>
                About {{ candidates.length }} candidates results
              </b-form-valid-feedback>
              <b-form-invalid-feedback>
                No Data Available
              </b-form-invalid-feedback>
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="4"
            md="2"
            lg="2"
            class="py-1 text-left"
          >
            <b-button
              class="w-100"
              variant="primary"
              @click="search"
            >
              search
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-row class="container">
        <b-col
          v-for="tr in candidates"
          :key="tr.id"
          class="d-flex"
          cols="11"
          sm="8"
          md="5"
          lg="4"
          xl="3"
        >
          <b-card class="w-100">
            <b-card-title>
              <b-row>
                <b-col cols="8">
                  <h2>Candidate {{ tr.id }}</h2>
                </b-col>
                <b-col
                  class="d-flex justify-content-end"
                  cols="4"
                >
                  <VxTooltip
                    color="success"
                  >
                    <template #title>
                      {{ $t('Request Candidate') }}
                    </template>
                    <template #text>
                      {{ $t('Click to Request this Candidate') }}
                    </template>
                    <a @click="SendRequestCandidates(tr.id)">
                      <fa-icon
                        color="success"
                        pack="fas"
                        icon="envelope"
                      />
                    </a>
                  </VxTooltip>
                  <VxTooltip
                    color="success"
                  >
                    <template #title>
                      {{ $t('View more') }}
                    </template>
                    <template #text>
                      {{ $t('Click to View more') }}
                    </template>
                    <a
                      class="ml-1"
                      @click="ViewMore(tr)"
                    >
                      <fa-icon
                        color="success"
                        pack="fas"
                        icon="eye"
                      />
                    </a>
                  </VxTooltip>
                </b-col>
              </b-row>
            </b-card-title>
            <b-card-body>
              <b-row>
                <b-col
                  cols="12"
                >
                  <h3 class="section-title">
                    Qualifications
                  </h3>
                  {{ tr.qualifications }}
                </b-col>
                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <h3 class="section-title">
                    Profile Summary
                  </h3>
                  {{ tr.profile_summary }}
                </b-col>
                <b-col
                  v-if="tr.occupations.length>0"
                  cols="12"
                  class="mt-2"
                >
                  <h3 class="section-title">
                    Desired Job
                  </h3>
                  <b-row class="mt-2 mb-2">
                    <b-col>
                      <b-badge
                        v-for="(jobs, indexjobs) in tr.occupations"
                        :key="indexjobs"
                        pill
                        variant="light-success"
                        class="mx-1"
                      >
                        {{ jobs.title }}
                      </b-badge>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  class="mt-2"
                  cols="12"
                >
                  <h3 class="section-title">
                    Status
                  </h3>
                  <vx-badge :color="tr.status==='available'?'light-success':'danger'">
                    {{ tr.status }}
                  </vx-badge>
                </b-col>
                <b-col
                  class="mt-1"
                  cols="12"
                >
                  <b-row>
                    <b-col
                      cols="6"
                      class="mt-1"
                    >
                      <h3 class="section-title">
                        Work Premit
                      </h3>
                      <fa-icon
                        v-if="tr.work_permit"
                        pack="fas"
                        icon="check"
                        color="success"
                      />
                      <fa-icon
                        v-else
                        pack="fas"
                        icon="times"
                        color="danger"
                      />
                    </b-col>
                    <b-col
                      class="mt-1"
                      cols="6"
                    >
                      <h3 class="section-title">
                        Refugee Status
                      </h3>
                      <fa-icon
                        v-if="tr.refugee_status"
                        pack="fas"
                        icon="check"
                        color="success"
                      />
                      <fa-icon
                        v-else
                        pack="fas"
                        icon="times"
                        color="danger"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-col
        v-if="candidates.length===0"
        cols="12"
        class="text-center"
      >No Data Available
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ViewCandidate from '@/views/home/component/ViewCadidate.vue'

export default {
  components: { ViewCandidate },
  data() {
    return {
      searchInput: '',
      SearchStatus: null,
      viewMore: false,
      candidate: {},
    }
  },
  computed: {
    candidates() {
      return this.$store.getters['employee/candidates']
    },
  },
  watch: {
    searchInput(val) {
      if (!val) {
        this.$store.dispatch('employee/GetCandidates')
        this.SearchStatus = null
      }
    },
  },
  created() {
    this.$store.dispatch('employee/GetCandidates')
  },
  methods: {
    SendRequestCandidates(val) {
      if (this.$store.getters['auth/GetAuth']) {
        this.$store.dispatch('employee/RequestCandidate', { candidate_id: val })
      } else {
        this.$router.push({
          name: 'login',
        })
      }
    },
    ViewMore(val) {
      this.viewMore = true
      this.candidate = val
    },
    search() {
      if (this.searchInput === null) this.SearchStatus = null
      this.$store.dispatch('employee/GetCandidates')
    },
  },
}
</script>

<style scoped>
.section-title {
  color: #B59341;
}
</style>
